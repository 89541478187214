<template>
    <div class="manage-content">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="证件号">
                <el-input v-model="formInline.idcard" placeholder="证件号" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="订单号">
                <el-input v-model="formInline.code" placeholder="订单号"></el-input>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border :row-class-name="tableRowClassName" :header-cell-style="getRowClass">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="number" label="订单号" align="center"></el-table-column>
            <el-table-column prop="customer.name" label="被保险人姓名" align="center"></el-table-column>
            <el-table-column prop="customer.card_type_mean" label="证件类型" align="center"></el-table-column>
            <el-table-column prop="customer.card_number" label="证件号码" align="center"></el-table-column>
            <el-table-column prop="customer.sex_mean" label="性别" align="center"></el-table-column>
            <el-table-column prop="customer.birth" label="出生日期" align="center"></el-table-column>
            <el-table-column prop="customer.mobile" label="手机号" align="center"></el-table-column>
            <el-table-column prop="order_state_mean" label="订单状态" align="center"></el-table-column>
        </el-table>
        <div class="pagination_class" v-if="total > 0">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pagesize" layout="total, prev, pager, next" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import app from '../../../assets/api/apiurl'
export default {
    data() {
        return {
            formInline: {
                idcard: '',
                code: ''
            },
            tableData: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let { formInline, currentPage, pagesize } = this
            this.$ajax.get(app.API_URL + '/order/query_insured', {
                params: {
                    card_number: formInline.idcard,
                    page: currentPage,
                    limit: pagesize
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.total = res.data.cnt
                    this.tableData = res.data.data
                }
            }).catch((err) => {

            });
        },
        onSubmit() {
            console.log('submit!');
            this.getData()
        },
        tableRowClassName({ row, rowIndex }) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'double';
            } else {
                return '';
            }
        },
        getRowClass({ rowIndex }) {
            if (rowIndex == 0) {
                return 'background:#F6F6F8'
            } else {
                return ''
            }
        },
        handleCurrentChange(...args) {
            console.log(args)
            this.currentPage = args[0]
            this.getData()
        },

    }
}
</script>

<style lang="less" scoped>
.manage-content {
    margin-left: 34px;
    margin-right: 34px;
    padding-top: 33px;
    padding-bottom: 50px;
    .pagination_class {
        margin-top: 22px;
        text-align: right;
    }
}
</style>