<template>
	<div class="company_news_div_class">
		<div class="boday_div_news">
			<div v-for="(item,index) in company_data.slice((1-1)*pagesize,1*pagesize)" :key="index">
				<div class="title_top_text PingFangMedium">单位信息</div>
				<div class="list_news">
					<el-descriptions :column="1">
						<el-descriptions-item label="投保类型">{{item.insure_type_mean}}</el-descriptions-item>
						<el-descriptions-item label="乡镇村/单位名称">{{item.policy_holder_name}}</el-descriptions-item>
						<el-descriptions-item label="乡镇村街道">{{item.town}}</el-descriptions-item>
						<el-descriptions-item label="统一社会信用代码">{{item.policy_holder_card_number}}</el-descriptions-item>
						<el-descriptions-item label="营业执照照片" v-if="item.business_license_file">
							<div class="img_class_div" @click="image_click('http://oss.nuohuipay.cn/'+item.business_license_file.path)">
								<img :src="'http://oss.nuohuipay.cn/'+item.business_license_file.path">
							</div>
						</el-descriptions-item>
					</el-descriptions>
				</div>
			</div>
			<div v-if="company_data.length>2">
				<div class="arrow_div_class PingFangMedium" v-if="pagesize === 2" @click="pagesize = company_data.length">展开全部 <i class="el-icon-arrow-down"></i></div>
				<div class="arrow_div_class PingFangMedium" v-if="pagesize > 2" @click="pagesize = 2">收起 <i class="el-icon-arrow-up"></i></div>
			</div>
		</div>
		<div class="boday_div_news" style="margin-top: 20px">
			<div class="title_top_text PingFangMedium">联系人信息</div>
			<div class="list_news" v-if="company_data[0]">
				<el-descriptions :column="1">
					<el-descriptions-item label="联系人手机号">{{company_data[0].policy_holder_mobile}}</el-descriptions-item>
					<el-descriptions-item label="联系人姓名">{{company_data[0].contact_name}}</el-descriptions-item>
					<el-descriptions-item label="联系人邮箱">{{company_data[0].contact_email}}</el-descriptions-item>
					<el-descriptions-item label="联系人收件地址">{{company_data[0].contact_address}}</el-descriptions-item>
				</el-descriptions>
			</div>
		</div>
		<el-image-viewer v-if="ImgsListDialog" :on-close="closeViewer" :url-list="[ImgsList]" :z-index="9999"></el-image-viewer>
	</div>
</template>

<script>
    import app from '../../../assets/api/apiurl'
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    export default {
        name: "company_news",
        components: { ElImageViewer },
	    data(){
            return{
                company_data:[],
	            pagesize:2,
                ImgsListDialog:false,//预览
                ImgsList:'',//预览图
            }
	    },
	    mounted(){
            this.getcompany();
	    },
	    methods:{
            getcompany(){
                this.$ajax.get(app.API_URL+'/companylists',{
                    params:{}
                }).then(res=>{
					if (res.data.code === 1){
						this.company_data = res.data.data;
					}
                })
            },
            closeViewer() {// 关闭查看器
                this.ImgsListDialog = false
            },
            image_click(url){//图片预览
                this.ImgsListDialog = true;
                this.ImgsList = url;
            },
	    }
    }
</script>

<style lang="less" scoped>
	.company_news_div_class{
		padding: 20px;
		.boday_div_news{
			background: #FFFFFF;
			border-radius: 3px;
			border: 1px solid #E2E2E2;
			.title_top_text{
				margin-top: 20px;
				font-weight: 600;
				color: #333333;
				line-height: 26px;
				font-size: 18px;
				border-left: 4px solid #A72C1C;
				padding-left: 20px;
			}
			.list_news{
				margin-left: 25px;
				margin-top: 10px;
				.img_class_div{
					width: 100px;
					height: 100px;
					cursor: pointer;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
			.arrow_div_class{
				height: 43px;
				border-radius: 3px;
				border: 1px solid #A72C1C;
				line-height: 43px;
				color: #A72C1C;
				font-size: 14px;
				text-align: center;
				font-weight: bold;
			}
		}
	}
</style>