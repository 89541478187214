<template>
    <div class="order_list_table">
        <div class="boday_order">
            <div class="table_nav_div">
                <div @click="click_assembly=0" :class="click_assembly === 0 ?'class_click_color':''">基本信息</div>
                <div @click="click_assembly=1" :class="click_assembly === 1 ?'class_click_color':''">订单管理</div>
                <div @click="click_assembly=2" :class="click_assembly === 2 ?'class_click_color':''">参保人信息</div>
            </div>
            <div class="assembly_div">
                <company_news v-if="click_assembly === 0"></company_news>
                <order_list_table v-if="click_assembly === 1"></order_list_table>
                <insuredManagement v-if="click_assembly === 2"></insuredManagement>
            </div>
        </div>
	    <login :sign_in_dialog="sign_in_dialog" @sign_in_emit="sign_in_emit"></login>
    </div>
</template>

<script>
import company_news from './components/company_news'
import insuredManagement from './components/insured_management.vue'
import order_list_table from './components/order_list_table'
import login from './components/login'
export default {
    name: "order_list",
    components: {
        company_news,
        insuredManagement,
        order_list_table,
        login
    },
    data() {
        return {
            click_assembly: 0,
            sign_in_dialog:false,
        }
    },
	created(){
        let token = localStorage.getItem("token");
        if (!token) {
            this.sign_in_dialog = true;
        }
	},
	methods:{
        sign_in_emit() {
            localStorage.setItem('is_reload', 'Y');
            this.sign_in_dialog = false;
        },
	}
}
</script>

<style lang="less" scoped>
.order_list_table {
    padding: 30px 100px;
    min-width: 1200px;
    .boday_order {
        background: #ffffff;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: flex-start;
        .table_nav_div {
            border-right: 1px solid #f2f4f6;
            div {
                width: 150px;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                text-align: center;
                border-bottom: 1px solid #f2f4f6;
                cursor: pointer;
            }
            .class_click_color {
                background: #a72c1c;
                color: #fff;
            }
        }
        .assembly_div {
            width: calc(100% - 151px);
        }
    }
}
</style>